import React from 'react';
import styled from 'styled-components';

const StyledCategoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCategory = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  padding: 1rem;
  width: auto;
  height: 2.6rem;
  background: ${props => props.hex};
  color: white;
  border-radius: 10rem;
  font-family: var(--fontSecondary);
  span {
    margin-top: -1.5px;
    margin-left: -1.5px;
    margin-right: 0.5rem;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Category = ({ category }) => (
  <>
    <StyledCategory hex={category.colorHex}>
      <span>+</span>
      <span>{category.title}</span>
    </StyledCategory>
  </>
);

const CategoryList = ({ categories }) => (
  <StyledCategoryList>
    {categories.map(category => (
      <Category key={category.id} category={category} />
    ))}
  </StyledCategoryList>
);

export default CategoryList;
